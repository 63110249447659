import React from "react";
import Portfolios from "./inc/Portfolios";

import CallToAction from "./inc/CallToAction";

function Portfolio() {
  return (
    <div>
      <div class="jumbotron portfolio-header text-center d-flex justify-content-center align-items-center">
        <div class="container">
          <h1 className="display-3 ">Our Portfolio</h1>
          <div className="underline mx-auto"></div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="card shadow">
            <div className="card-body b-2">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <h2 className="main-heading text-center">
                    Portfolio Experience
                  </h2>
                  <div className="underline mx-auto"></div>
                  <h5 className="text-center">
                    Sab FCW Ltd has over six years experience
                    in the Minning, Oil & Gas and Manufacturing Industry.
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <Portfolios />

          

        </div>
      </section>
      <CallToAction />
    </div>
  );
}

export default Portfolio;
