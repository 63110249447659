import React, { useState } from "react";
import "../gallery.css";
import { FaTimesCircle } from "react-icons/fa";
import Img46 from "../../images/gallery/46.jpeg";
import Img47 from "../../images/gallery/47.jpeg";
import Img48 from "../../images/gallery/48.jpeg";
import Img49 from "../../images/gallery/49.jpeg";
import Img50 from "../../images/gallery/50.jpeg";
import Img51 from "../../images/gallery/51.jpeg";
import Img52 from "../../images/gallery/52.jpeg";
import Img53 from "../../images/gallery/53.jpeg";
import Img54 from "../../images/gallery/54.jpeg";
import Img55 from "../../images/gallery/55.jpeg";
import Img56 from "../../images/gallery/56.jpeg";
import Img57 from "../../images/gallery/57.jpeg";
import Img58 from "../../images/gallery/58.jpeg";
import Img59 from "../../images/gallery/59.jpeg";
import Img60 from "../../images/gallery/60.jpeg";
import Img61 from "../../images/gallery/61.jpeg";
import Img62 from "../../images/gallery/62.jpeg";
import Img63 from "../../images/gallery/63.jpeg";
import Img64 from "../../images/gallery/64.jpeg";
import Img65 from "../../images/gallery/65.jpeg";
import Img66 from "../../images/gallery/66.jpeg";
import Img67 from "../../images/gallery/67.jpeg";
import Img68 from "../../images/gallery/68.jpeg";
import Img69 from "../../images/gallery/69.jpeg";
import Img70 from "../../images/gallery/70.jpeg";
import Img71 from "../../images/gallery/71.jpeg";
import Img72 from "../../images/gallery/72.jpeg";

const ImgGallery3 = () => {
  let data = [
    {
      id: 46,
      imgSrc: Img46,
    },
    {
      id: 47,
      imgSrc: Img47,
    },
    {
      id: 48,
      imgSrc: Img48,
    },
    {
      id: 49,
      imgSrc: Img49,
    },
    {
      id: 50,
      imgSrc: Img50,
    },
    {
      id: 51,
      imgSrc: Img51,
    },
    {
      id: 52,
      imgSrc: Img52,
    },
    {
      id: 53,
      imgSrc: Img53,
    },
    {
      id: 54,
      imgSrc: Img54,
    },
    {
      id: 56,
      imgSrc: Img56,
    },
    {
      id: 57,
      imgSrc: Img57,
    },
    {
      id: 58,
      imgSrc: Img58,
    },
    {
      id: 59,
      imgSrc: Img59,
    },
    {
      id: 60,
      imgSrc: Img60,
    },
    {
      id: 61,
      imgSrc: Img61,
    },

    {
      id: 62,
      imgSrc: Img62,
    },
    {
      id: 63,
      imgSrc: Img63,
    },
    {
      id: 64,
      imgSrc: Img64,
    },
    {
      id: 65,
      imgSrc: Img65,
    },
    {
      id: 66,
      imgSrc: Img66,
    },
    {
      id: 67,
      imgSrc: Img67,
    },
    {
      id: 68,
      imgSrc: Img68,
    },
    {
      id: 69,
      imgSrc: Img69,
    },
    {
      id: 70,
      imgSrc: Img70,
    },
    {
      id: 71,
      imgSrc: Img71,
    },
    {
      id: 72,
      imgSrc: Img72,
    },
  ];

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  return (
    <>
      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} />
        <FaTimesCircle onClick={() => setModel(false)} />
      </div>
      <div className="gallery">
        {data.map((item, index) => {
          return (
            <div
              className="pics"
              key={index}
              onClick={() => getImg(item.imgSrc)}
            >
              <img src={item.imgSrc} style={{ width: "100%" }} alt="" />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ImgGallery3;
