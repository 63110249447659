import React from 'react';
import MainServices from '../pages/inc/MainServices';
import CallToAction from '../pages/inc/CallToAction';



function Service() {
    return(
        <div>
            <div class="jumbotron services-header text-center d-flex justify-content-center align-items-center">
        <div class="container">
            
          <h1 className="display-3 ">Our Services</h1>
          <div className="underline mx-auto"></div>
          
        </div>
        </div>
        <section className="section pb-0">
            <div className="container">
            <MainServices />
           <hr className="m-5" />
            </div>
        
            
        </section>
        <CallToAction />
        </div>
    );
}

export default Service;