import React from 'react';
import {Link} from 'react-router-dom';
import Service3 from "../../images/rental.jpeg";
import Service4 from "../../images/constrution.jpeg";
import Steeltank from "../../images/steel.jpeg";
import Coating from "../../images/coating.jpeg";





function OurServices() {
    return(
        <section className="section border-top">
        <div className="container">
            <div className="row">
                <div className="col-md-12 mb-4 text-center">
                    <h3 className="main-heading">Our Services</h3> 
                    <div className="underline mx-auto"></div>
                    </div>
                    <div className="col-md-3 text-center">
                        <div className="card shadow">
                                <img src={Service4} className="w-100 border-botton" alt="services"/>
                             <div className="card-body">
                                <h6 className="text-center">Fabrication</h6>
                                <div clssName="underline"></div>
                               
                                <Link to="/service" className="btn btn-warning shadow">read more</Link>
                            </div>
                        </div>

                        
                            
                    </div> 

                    <div className="col-md-3 text-center">
                        <div className="card shadow">
                                <img src={Steeltank} className="w-100 border-botton" alt="services"/>
                             <div className="card-body">
                                <h6 className="text-center">Waterproofing</h6>
                                <div clssName="underline"></div>
                                
                                <Link to="/service" className="btn btn-warning shadow">read more</Link>
                            </div>
                        </div>

                        
                            
                    </div> 

                    <div className="col-md-3 text-center">
                        <div className="card shadow">
                                <img src={Service3} className="w-100 border-botton" alt="services"/>
                             <div className="card-body">
                                <h6 className="text-center">Equipment Supply Rental and Maintenance</h6>
                                <div clssName="underline"></div>
                                
                                <Link to="/service" className="btn btn-warning shadow">read more</Link>
                            </div>
                        </div>

                        
                            
                    </div> 

                    <div className="col-md-3 text-center">
                        <div className="card shadow">
                                <img src={Coating} className="w-100 border-botton" alt="services"/>
                             <div className="card-body">
                                <h6 className="text-center">Coating</h6>
                                
                                <Link to="/service" className="btn btn-warning shadow">read more</Link>
                            </div>
                        </div>

                        
                            
                    </div> 
                        
                <div className="col-md-12 mb-4 text-center">
              <div className=" justify-content-center mt-5">
                <Link to="/service" className="btn btn-warning shadow">
                  View More of our Services
                </Link>
              </div>
            </div>
            </div>
        </div>
    </section>

    );
}

export default OurServices;