import React from "react";
import Cirrus from "../../images/cirrus.jpeg";
import Bank from "../../images/bank.png";
import Quantum from "../../images/quantum.jpeg";
import Orsam from "../../images/orsam.jpeg";
import Sewerage from "../../images/sewerage.png";
import Meridian from "../../images/meridian.jpeg";
import Psc from "../../images/psc.jpeg";
import Newmount from "../../images/newmount.png";
import Pwsl from "../../images/pwsl.jpeg";
import Galfco from "../../images/galfco.png";
import Anglogold from "../../images/anglogold.png";


function Portfolios() {
  return (
    <section className="section">
      <div className="container">
        <div className="row justify-content-center">
          <div>
            <div>
              <h2 className="main-heading text-center">
                SAB FCW Ltd – SELECTED PROJECTS COMPLETED IN GHANA
              </h2>
              <div className="underline mx-auto"></div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Cirrus} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>Cirrus Oil Depot</h5>
                    <p>
                      4 Tanks Roof blasting - <b>Takoradi Facility</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Bank} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>The Bank Hospital</h5>
                    <p>
                      Underground Water storage Tank Coating - <b>Cantonment Accra</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Quantum} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>Quantum Terminals</h5>
                    <p>
                      Fire Hydrant Tank Sandblasting and Coating -{" "}
                      <b>Nzemaa - Anokye Facility</b>Nzemaa - Anokye Facility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Orsam} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>Orsam Oil and Gas ltd</h5>
                    <p>
                      West African Gas Plant Construction -{" "}
                      <b>Nsemaa - Sanzule Facility</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Sewerage} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>Ghana Sewerage Systems Ltd</h5>
                    <p>
                      4 USB Tank blasting and Coating -{" "}
                      <b>Accra - Lavender Hill Facility.</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Meridian} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>meridian ports Services</h5>
                    <p>
                      Gantry Crane sandblasting and coating -{" "}
                      <b>Tema Port Facility</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-2">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Psc} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>PSC Tema Ship Yard Ltd</h5>
                    <p>
                      7-Neggar Vessel sandblasting and coating -{" "}
                      <b>Tema Drydock facility</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Newmount} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>New Mount Ghana Ltd</h5>
                    <p>
                      Steel structures sandblasting and coating -{" "}
                      <b>Akyem Plant facility</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Pwsl} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>PWSL Tank Farms Ltd</h5>
                    <p>
                      4 Tanks Sandblastig and Painting - <b>Tema Facility</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-2">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Galfco} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>GALFCO</h5>
                    <p>
                      8 Concrete Silos Internal Sandblasting and Painting -{" "}
                      <b>Tema Facility</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card shadow">
              <div className="card-body b-2 p-3">
                <div className="row">
                  <div className="col-md-4 text-center">
                    <img src={Anglogold} className="w-100 p-2" alt="about" />
                  </div>
                  <div className="col-md-8">
                    <h5>AngloGold Ashanti</h5>
                    <p>
                      Sandblasting ans Painting of Ball mill and steel
                      structures (Shutdown Maintenance) -{" "}
                      <b>Tarkwa - Induapreium Facility</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr></hr>

     </div>
    </section>
  );
}

export default Portfolios;
