import React, { useState } from "react";
import "../gallery.css";
import { FaTimesCircle } from "react-icons/fa";
import Img21 from "../../images/gallery/21.jpeg";
import Img22 from "../../images/gallery/22.jpeg";
import Img23 from "../../images/gallery/23.jpeg";
import Img24 from "../../images/gallery/24.jpeg";
import Img25 from "../../images/gallery/25.jpeg";
import Img26 from "../../images/gallery/26.jpeg";
import Img27 from "../../images/gallery/27.jpeg";
import Img28 from "../../images/gallery/28.jpeg";
import Img29 from "../../images/gallery/29.jpeg";
import Img30 from "../../images/gallery/30.jpeg";
import Img31 from "../../images/gallery/31.jpeg";
import Img32 from "../../images/gallery/32.jpeg";
import Img33 from "../../images/gallery/33.jpeg";
import Img34 from "../../images/gallery/34.jpeg";
import Img35 from "../../images/gallery/35.jpeg";
import Img36 from "../../images/gallery/36.jpeg";
import Img37 from "../../images/gallery/37.jpeg";
import Img38 from "../../images/gallery/38.jpeg";
import Img39 from "../../images/gallery/39.jpeg";
import Img40 from "../../images/gallery/40.jpeg";
import Img41 from "../../images/gallery/41.jpg";
import Img43 from "../../images/gallery/43.jpeg";
import Img44 from "../../images/gallery/44.jpeg";
import Img45 from "../../images/gallery/45.jpeg";

const ImgGallery2 = () => {
  let data = [
    {
      id: 21,
      imgSrc: Img21,
    },
    {
      id: 22,
      imgSrc: Img22,
    },
    {
      id: 23,
      imgSrc: Img23,
    },
    {
      id: 24,
      imgSrc: Img24,
    },
    {
      id: 25,
      imgSrc: Img25,
    },
    {
      id: 26,
      imgSrc: Img26,
    },
    {
      id: 27,
      imgSrc: Img27,
    },
    {
      id: 28,
      imgSrc: Img28,
    },
    {
      id: 29,
      imgSrc: Img29,
    },
    {
      id: 30,
      imgSrc: Img30,
    },
    {
      id: 31,
      imgSrc: Img31,
    },
    {
      id: 32,
      imgSrc: Img32,
    },
    {
      id: 33,
      imgSrc: Img33,
    },
    {
      id: 34,
      imgSrc: Img34,
    },
    {
      id: 35,
      imgSrc: Img35,
    },

    {
      id: 36,
      imgSrc: Img36,
    },
    {
      id: 37,
      imgSrc: Img37,
    },
    {
      id: 38,
      imgSrc: Img38,
    },
    {
      id: 39,
      imgSrc: Img39,
    },
    {
      id: 40,
      imgSrc: Img40,
    },
    {
      id: 41,
      imgSrc: Img41,
    },
    {
      id: 43,
      imgSrc: Img43,
    },
    {
      id: 44,
      imgSrc: Img44,
    },
    {
      id: 45,
      imgSrc: Img45,
    },
  ];

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  return (
    <>
      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} />
        <FaTimesCircle onClick={() => setModel(false)} />
      </div>
      <div className="gallery">
        {data.map((item, index) => {
          return (
            <div
              className="pics"
              key={index}
              onClick={() => getImg(item.imgSrc)}
            >
              <img src={item.imgSrc} style={{ width: "100%" }} alt="" />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ImgGallery2;
