import React from 'react';
import ImgGallery from '../pages/inc/ImgGallery';
import { Link } from 'react-router-dom'


function Gallery () {


    return(

      <div>
        <div className="jumbotron about-header text-center d-flex justify-content-center align-items-center">
            <div className="container">
                <h1 className="display-3 ">Our Gallery</h1>
            <div className="underline mx-auto"></div>
        </div>
      </div>
      <section className="section">
        <div className="container">
        
            {/*  Gallery Script */}
           
        <ImgGallery/>


          {/*  Gallery */}
         </div>
         <div className="col-md-12 mb-4 text-center">
              <div className=" justify-content-center mt-5">
                <Link to="/moregallery" className="btn btn-warning shadow">
                  View More Images 
                </Link>
              </div>
            </div>

      </section>
    </div>
    );
}

export default Gallery;