import React from 'react';
import Sab from '../../images/sab.jpg';
import Addo from "../../images/addo.jpg";
import Lawrence from "../../images/lawrence.jpeg";
import Jennifer from "../../images/jeni.png";
import Ishmael from "../../images/ishmael.jpg";
import Damalie from "../../images/damalie.jpg";
import Francis from "../../images/francis.jpg";


function Team() {
    return (
      <section className="section mt-4">
        <div className="container">
          <div className="card">
            <div className="card-content shadow">
              <div className="row justify-content-center mb-4">
                <div className="col-md-12 text-center mt-4">
                  <h2 className="main-heading">
                    CONSTRUCTION & ENGINEERING EXPERTS
                  </h2>
                  <p>
                    SAB has brought together a leadership team with diverse
                    background and relevant expertise to provide a complete
                    picture to our partners and clients. The background and
                    areas of expertise include Legal, chemical, civil, and
                    mechanical engineering in Ghana and Europe. Management team
                    of SAB consists of:
                  </p>
                  <div className="underline mx-auto"></div>
                  <h5 className="sub-heading">ADMINISTRATION</h5>
                </div>

                <div className="col-md-4 text-center">
                  <div className="card">
                    <div className="card-content">
                      <img src={Sab} className="w-100 p-2" alt="about" />
                    </div>
                    <div className="sub-heading">Mr. Samuel Badu Adotey</div>
                    <p>CEO & Head of Engineering</p>
                    <p>0545379992</p>
                  </div>
                </div>

                <div className="col-md-4 text-center">
                  <div className="card">
                    <div className="card-content">
                      <img src={Addo} className="w-100 p-2" alt="about" />
                    </div>
                    <div className="sub-heading">Enock Akueteh Addo</div>
                    <p>Director</p>
                    <p>0244466506</p>
                  </div>
                </div>

                <div className="col-md-4 text-center">
                  <div className="card">
                    <div className="card-content">
                      <img src={Lawrence} className="w-100 p-2" alt="about" />
                    </div>
                    <div className="sub-heading">Lawrence Ofori Yeboah</div>
                    <p>Director</p>
                    <p>0247722888</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="m-5" />
          <div className="row justify-content-center mt-5">
            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-content">
                  <img src={Jennifer} className="w-100 p-2" alt="about" />
                </div>
                <div className="sub-heading">Jennifer Coffie</div>
                <p>HEAD OF ACCOUNTS</p>
              </div>
            </div>

            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-content">
                  <img src={Ishmael} className="w-100 p-2" alt="about" />
                </div>
                <div className="sub-heading">Ishmael Nartey</div>
                <p>PROJECT SUPERVISOR</p>
              </div>
            </div>

            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-content">
                  <img src={Damalie} className="w-100 p-2" alt="about" />
                </div>
                <div className="sub-heading">Damalie, Andy Bright</div>
                <p>PROJECT ENGINEER</p>
              </div>
            </div>

            <div className="col-md-3 text-center">
              <div className="card">
                <div className="card-content">
                  <img src={Francis} className="w-100 p-2" alt="about" />
                </div>
                <div className="sub-heading">Tenkorang Francis</div>
                <p>TECHNICIAN</p>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Team;