import React from "react";
import ImgGallery3 from "../pages/inc/ImgGallery3";
import { Link } from "react-router-dom";

function SomeMoreGallery() {
  return (
    <div>
      <div className="jumbotron about-header text-center d-flex justify-content-center align-items-center">
        <div className="container">
          <h1 className="display-3 ">Our Gallery</h1>
          <div className="underline mx-auto"></div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          {/*  Gallery Script */}

          <ImgGallery3 />

          {/*  Gallery */}
        </div>
        <div className="col-md-12 mb-4 text-center">
          <div className=" justify-content-center mt-5">
            <Link to="/moregallery" className="btn btn-warning shadow">
              Previouse Images
            </Link>
            <Link to="/gallery" className="btn btn-primary shadow">
              Main Gallery
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SomeMoreGallery;
