import React from "react";

import Service3 from "../../images/rental.jpeg";
import Service4 from "../../images/constrution.jpeg";
import Steeltank from "../../images/steel.jpeg";
import Coating from "../../images/coating.jpeg";
import Borehole from "../../images/borehole.jpeg";



function MainServices() {
  return (
    <section className="section border-top">
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-4 text-center">
            <h3 className="main-heading">SAB FCW Ltd Services</h3>
            <div className="underline mx-auto"></div>
          </div>
          <div className="col-md-3">
            <div className="card shadow">
              <img
                src={Service4}
                className="w-100 border-botton"
                alt="services"
              />
              <div className="card-body">
                <h6 className="text-center">Fabrication</h6>
                <div clssName="underline"></div>
                <p>
                  <ul>
                    <li>Structural Steel Construction</li>
                    <li>Steel Tank Construction</li>
                    <li>Steel Roof truss Construction</li>
                    <li>Steel Bullastrade and Gates Construction</li>
                    <li>Pipleine (both Steel & HDPE) Construction</li>
                    <li>Lifting and Erection Services</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="card shadow">
              <img
                src={Steeltank}
                className="w-100 border-botton"
                alt="services"
              />
              <div className="card-body">
                <h6 className="text-center">Waterproofing</h6>
                <div clssName="underline"></div>
                <p>
                  <ul>
                    <li>Flat Concrete Roof Waterproofing</li>
                    <li>Damp Walls Repair Services</li>
                    <li>Swimming Pool Waterproof</li>
                  </ul>
                </p>
                .
              </div>
            </div>
          </div>

          <div className="col-md-3 ">
            <div className="card shadow">
              <img
                src={Service3}
                className="w-100 border-botton"
                alt="services"
              />
              <div className="card-body">
                <h6 className="text-center">
                  Equipment Supply Rental and Maintenance
                </h6>
                <div clssName="underline"></div>
                <p>
                  <ul>
                    <li>Earth Moving Equipment</li>
                    <li>Borehole Drilling Equipment</li>
                    <li>Corrosion Control Equipment</li>
                    <li>Welding and Fabrication Machines</li>
                    <li>lifting Equipment</li>
                    <li>Scaffolding (erection & dismantling services)</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="card shadow">
              <img
                src={Coating}
                className="w-100 border-botton"
                alt="services"
              />
              <div className="card-body">
                <h6 className="text-center">Coating</h6>
                <div clssName="underline"></div>
                <p>
                  <ul>
                    <li>Sandblasting and Painting</li>
                    <li>Hydro Blasting and Painting</li>
                    <li>Slurry Blasting and Painting</li>
                    <li>Underground Pipeline Viscoelectric Band Wrapping</li>
                    <li>Rubber Linning Solution</li>
                    <li>Industrial Floor Epoxy Coating</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="card shadow">
              <img
                src={Borehole}
                className="w-100 border-botton"
                alt="services"
              />
              <div className="card-body">
                <h6 className="text-center">Drilling</h6>
                <div clssName="underline"></div>
                <p>
                  <ul>
                    <li>Community Water Borehole Drilling</li>
                    <li>Mineral Exploration Purpose </li>
                    <li>Site Evaluation Purpose</li>
                    
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainServices;
