import React from 'react';



function Map() {
    return (
      <section className="section">
        <div className="row">
          <div className="col-md-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.0568521361897!2d-0.08415668523339577!3d5.704926995865108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x45edaed9ee9dd239!2zNcKwNDInMTcuNyJOIDDCsDA0JzU1LjEiVw!5e0!3m2!1sen!2sgh!4v1643292099093!5m2!1sen!2sgh"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              title="location"
            ></iframe>
            
          </div>
        </div>
      </section>
    );
}

export default Map;