import React from 'react';
import Cirrus from '../../images/cirrus.jpeg';
import Bank from '../../images/bank.png';
import Quantum from '../../images/quantum.jpeg';
import Orsam from '../../images/orsam.jpeg';
import Sewerage from '../../images/sewerage.png';
import Meridian from '../../images/meridian.jpeg';
import { Link } from 'react-router-dom';

function PortfolioSection() {
    return (
      <section className="section bg-c-yellow border-top">
        <div className="container">
          <div className="row justify-content-center">
            <div>
              <div>
                <h2 className="main-heading text-center">OUR PORTFOLIO</h2>
                <div className="underline mx-auto"></div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="card shadow">
                <div className="card-body b-2 p-3">
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <img src={Cirrus} className="w-100 p-2" alt="about" />
                    </div>
                    <div className="col-md-8">
                      <h5>Cirrus Oil Depot</h5>
                      <p>
                        4 Tanks Roof Blasting - <b>Takoradi Facility</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card shadow">
                <div className="card-body b-2 p-3">
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <img src={Bank} className="w-100 p-2" alt="about" />
                    </div>
                    <div className="col-md-8">
                      <h5>The Bank Hospital</h5>
                      <p>
                        Underground Water Storage Tank Coating -{" "}
                        <b>Cantonment Accra</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card shadow">
                <div className="card-body b-2 p-3">
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <img src={Quantum} className="w-100 p-2" alt="about" />
                    </div>
                    <div className="col-md-8">
                      <h5>Quantum Terminals</h5>
                      <p>
                        Fire Hydrant Tank Sandblasting and Coating -{" "}
                        <b>Nzemaa - Anokye Facility</b>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card shadow">
                <div className="card-body b-2 p-3">
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <img src={Orsam} className="w-100 p-2" alt="about" />
                    </div>
                    <div className="col-md-8">
                      <h5>Orsam Oil and Gas Ltd</h5>
                      <p>
                        West African Gas Plant Construction -{" "}
                        <b>Nsemaa - Sanzule Facility</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card shadow">
                <div className="card-body b-2 p-3">
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <img src={Sewerage} className="w-100 p-2" alt="about" />
                    </div>
                    <div className="col-md-8">
                      <h5>Ghana Sewerage Systems ltd</h5>
                      <p>
                        4 USB Tank Blasting and Coating - Accra -{" "}
                        <b>Lavender Hill Facility</b>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card shadow">
                <div className="card-body b-2 p-3">
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <img src={Meridian} className="w-100 p-2" alt="about" />
                    </div>
                    <div className="col-md-8">
                      <h5>Meridian Ports Services</h5>
                      <p>
                        Gantry Crane sandblasting and coating -{" "}
                        <b>Tema Port Facility</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-4 text-center">
              <div className=" justify-content-center mt-5">
                <Link to="/portfolio" className="btn btn-warning shadow">
                  View More or our Portfolios
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default PortfolioSection;