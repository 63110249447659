import React from 'react';
import Team1 from '../images/about1.jpeg';
import Vmv from './inc/Vmv';
import Team from './inc/Team';

function Aboutus() {
    return(
        <div>
            <div class="jumbotron about-header text-center d-flex justify-content-center align-items-center">
                <div class="container">
                    <h1 className="display-3 ">About Us</h1>
                <div className="underline mx-auto"></div>
          
            </div>
        </div>
            <section className="section bt-4">
            <div className="container">
                <div className="card shadow bt-4">
                    <div className="card-body p-2">
                    <div className="row justify-content-center">
        
        <div className="col-md-6">
            <h3 className="main-heading">Our Story</h3>
            <hr />
            <div>
            <p>SAB is Ghanaian registered construction engineering, 
                sand blasting & coating, equipment rentals and concrete 
                structural waterproofing company dedicated to serving 
                material resourced industries by designing and providing 
                quality engineering services.</p>
                
               <p> We have one of the most capable engineering professional 
                staffs in the industry which deliver reliable construction 
                engineering and specialty coatings application services for 
                infrastructural development and rehabilitation projects in 
                regions experiencing growth in Natural Resources Development. 
                Our focal point is to provide service that includes:
              <ul>
                  <li>Oil & Gas Services</li>
                  <li>Minning Services</li>
                  <li>Equipment Rentals Services</li>
                  <li>Plants & Building Services</li>
                  
              </ul>
            </p>
            
            </div>
        </div>
        <div className="col-md-4">
        
            <div className="card">
                <div claasName="card-body shadow border">
                <img src={Team1} className="w-100 p-2" alt="about"/> 
                </div>
            </div>
         
        </div>
    </div>
    <div>
    
    </div>
                    </div>
                </div>
                

                
            </div>
            {/* Values Mission and Vision Section*/}
            <Vmv />

             {/* Team Section */}
            <Team />

            </section>
        </div>
       
       
     
    );
}

export default Aboutus;