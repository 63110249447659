import React from "react";
import "./App.css";
import TopNav from "./components/inc/TopNav";
import Navbar from "./components/inc/Navbar";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Service from "./components/pages/Service";
import Portfolio from "./components/pages/Portfolio";
import Gallery from "./components/pages/Gallery";
import Contact from "./components/pages/Contact";
import Footer from "./components/inc/Footer";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MoreGallery from "./components/pages/MoreGallery";
import SomeMoreGallery from "./components/pages/SomeMoreGallery";


function App() {
  return (
    <Router>
      <TopNav />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/about" element={<About />} />

        <Route path="/service" element={<Service />} />

        <Route path="/portfolio" element={<Portfolio />} />

        <Route path="/gallery" element={<Gallery />} />

        <Route path="/contact" element={<Contact />} />

        <Route path="/moregallery" element={<MoreGallery />} />

        <Route path="/somemoregallery" element={<SomeMoreGallery />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
